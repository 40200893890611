import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const commonRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('../components/Login.vue'),
    },
    {
        path: '/other', // 点击侧边栏跳到一个单独的路由页面，需要定义，层级和其他顶级路由一样
        name: 'other',
        meta: { title: '单独的路由' },
        component: () => import('../views/Other.vue'),
    },
    {
        path: '/404',
        name: '404',
        meta: { title: '404' },
        component: () => import('../components/404.vue'),
    },
    { path: '/', redirect: '/home' },
]

// 本地所有的页面 需要配合后台返回的数据生成页面
export const asyncRoutes = {
    home: {
        path: 'home',
        name: 'home',
        meta: { title: '主页' },
        component: () => import('../views/Home.vue'),
    },
    password: {
        path: 'password',
        name: 'password',
        meta: { title: '修改密码' },
        component: () => import('../views/Password.vue'),
    },
    msg: {
        path: 'msg',
        name: 'msg',
        meta: { title: '通知消息' },
        component: () => import('../views/Msg.vue'),
    },
    userinfo: {
        path: 'userinfo',
        name: 'userinfo',
        meta: { title: '用户信息' },
        component: () => import('../views/UserInfo.vue'),
    },
    userpage: {
        path: 'userpage',
        name: 'userpage',
        meta: { title: '用户管理' },
        component: () => import('../views/UserPage.vue'),
    },
    Role: {
        path: 'Role',
        name: 'Role',
        meta: { title: '角色管理' },
        component: () => import('../views/Role.vue'),
    },
    saveForm: {
        path: 'saveForm',
        name: 'saveForm',
        meta: { title: '保存保单' },
        component: () => import('../views/saveForm.vue'),
    },
    salesBusinessList: {
        path: 'salesBusinessList',
        name: 'salesBusinessList',
        meta: { title: '查询保单' },
        component: () => import('../views/businessList.vue'),
    },
    DataStatistics: {
        path: 'DataStatistics',
        name: 'DataStatistics',
        meta: { title: '数据统计' },
        component: () => import('../views/DataStatistics.vue'),
    },
    Business: {
        path: 'Business',
        name: 'Business',
        meta: { title: '保单统计' },
        component: () => import('../views/Business.vue'),
    },
    Business2: {
        path: 'Business2',
        name: 'Business2',
        meta: { title: '保单查询2' },
        component: () => import('../views/Business2.vue'),
    },
    CompantManage: {
        path: 'CompantManage',
        name: 'CompantManage',
        meta: { title: '公司管理' },
        component: () => import('../views/CompantManage.vue'),
    },
    Organization: {
        path: 'Organization',
        name: 'Organization',
        meta: { title: '机构管理' },
        component: () => import('../views/Organization.vue'),
    },
    Source: {
        path: 'Source',
        name: 'Source',
        meta: { title: '来源设置' },
        component: () => import('../views/Source.vue'),
    },
    CarTypeList: {
        path: 'CarTypeList',
        name: 'CarTypeList',
        meta: { title: '车辆类型' },
        component: () => import('../views/CarTypeList.vue'),
    },
    verify: {
        path: 'verify',
        name: 'verify',
        meta: { title: '修改审核' },
        component: () => import('../views/verify.vue'),
    },
    report: {
        path: 'report',
        name: 'report',
        meta: { title: '数据报表' },
        component: () => import('../views/report.vue'),
    },
    uploadFilePage: {
        path: 'uploadFilePage',
        name: 'uploadFilePage',
        meta: { title: '上传数据' },
        component: () => import('../views/UploadFilePage.vue'),
    },
}

const createRouter = () => new Router({
    routes: commonRoutes,
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router