import axios from 'axios'
import { Message } from 'view-design'
import router from '@/router'
import { showLoading, closeLoading } from '@/utils/loading'
import { resetTokenAndClearUser } from '@/utils'

const BASE_URL = process.env.VUE_APP_SERVER_URL


const service = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
})


service.interceptors.request.use(config => {
    showLoading()
    if (localStorage.getItem('token')) {
        // config.headers.Authorization = localStorage.getItem('token')
        config.headers.token = localStorage.getItem('token')
        // console.log(localStorage.getItem('token'));
    }
    return config
}, (error) => Promise.reject(error))

service.interceptors.response.use(response => {
    closeLoading()
    const res = response.data
    // 这里是接口处理的一个示范，可以根据自己的项目需求更改
    // 错误处理
    if (res.code != 200 && res.msg) {
        // Message.error({
        //     content: res.msg,
        // })

        // token 无效，清空路由，退出登录
        if (res.code == 401) {
            resetTokenAndClearUser()
            return Promise.reject()
           // router.push('login')
        }
        if (res.code == 400) {
          Message.error({
            content: res.msg,
          })
        }
        console.log(1111)
        return Promise.reject()
    }

    // 如果接口正常，直接返回数据
    return res
}, (error) => {
  if (!error.response.data.msg) {
    resetTokenAndClearUser()
    router.push('login')
  }
  let msg = error.response.data.msg || '系统错误'
    closeLoading()
    if (error.response.data.code === 401) {
      resetTokenAndClearUser()
      router.push('login')
    }
    if (error.response.data.code === 400) {
      Message.error({
        content: '缺少参数',
      })
      resetTokenAndClearUser()
      router.push('login')
    }
    if (error.name == 'Error') {
        Message.error({
            content: msg,
        })
    } else {
        Message.error({
            content: msg,
        })
    }

    return Promise.reject(msg)
})


export const post = (url, params) => service({
        method: 'post',
        url,
        data: params,
        transformRequest: [function (data) {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
            }],
    })

export const put = (url, params) => service({
      method: 'put',
      url: BASE_URL + url,
      data: params,
      transformRequest: [function (data) {
        let ret = ''
        for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

  export const deleteRequest = (url) => service({
      method: 'delete',
      url: BASE_URL + url,
    })

  export const getRequest = (url) => service({
      method: 'get',
      url: encodeURI(BASE_URL + url),
    })

// export default service
