import * as request from '@/utils/request'

export function fetchUserData() {
    return request.getRequest('https://api.github.com/users/woai3c')
}

export function fetchCzList(url) {
    return request.getRequest(url)
}

export function addCzInfo(data) {
    return request.post('addCzPrice', data)
}

export function updatecz(data) {
    return request.put('updateCzPrice', data)
}

export function deleteCz(cid) {
    return request.deleteRequest('delCzPrice?cid=' + cid)
}

export function findAllCz(data) {
    return request.getRequest('findAllCz?' + data)
}

export function findAllThick(data) {
    return request.getRequest('findAllThick?' + data)
}

export function findAllWide(data) {
    return request.getRequest('findAllWide?' + data)
}
export function findAllLen() {
    return request.getRequest('findAllLen')
}
export function findAllMachiniList(param) {
    let timestamp = (new Date()).valueOf()
    return request.getRequest(('findAllMachiniList?timestamp=' + timestamp + '&' + param))
}

export function addMachini(param) {
    return request.post('saveMachini', param)
}

export function updateMachini(data) {
    return request.put('updateMachini', data)
}

export function findSurface() {
    return request.getRequest('findSurface')
}

export function findFilm() {
    return request.getRequest('findFilm')
}

export function delMechini(mid) {
    return request.deleteRequest('delMachini?mid=' + mid)
}

export function userLogin(data) {
    return request.post('login', data)
}

export function findUserList(data) {
    let timestamp = (new Date()).valueOf()
    return request.getRequest(('findUserList?timestamp=' + timestamp + data))
}

export function addUser(data) {
    return request.post('addUser', data)
}

export function delUer(uid) {
    return request.deleteRequest('delUser?uid=' + uid)
}

export function findRoleList(data) {
    let timestamp = (new Date()).valueOf()
    return request.getRequest('findAllRole?timestamp=' + timestamp + '&' + data)
}

export function findAllRole() {
    return request.getRequest('queryRoleList')
}

export function saveRole(data) {
    return request.post('saveRole', data)
}
export function updateRole(data) {
    return request.put('updateRole', data)
}

export function delRole(data) {
    return request.deleteRequest('delRole?rid=' + data)
}

export function updateUser(data) {
    return request.put('updateUserInfo', data)
}

export function findTreeList() {
    return request.getRequest('findPermissionTreeList')
}

export function findTreeByRid(data) {
    let timestamp = (new Date()).valueOf()
    return request.getRequest('findRolePermissionTreeList?timestamp=' + timestamp + '&' + data)
}
export function saveRolePermission(data) {
    return request.post('saveRolePermission', data)
}


export function findMenuIten(data) {
    let timestamp = (new Date()).valueOf()
    return request.getRequest('findUserPermission?timestamp=' + timestamp + '&' + data)
}


export function findUpdateTime() {
    return request.getRequest('findUpdateTime')
}


export function updataUserPswd(data) {
    return request.put('updataUserPswd', data)
}


// ___________________
export function addBusinessVo(data) {
    return request.post('addBusinessVo', data)
}

export function findInsuranceTypeList(data) {
    return request.getRequest('findInsuranceTypeList')
}

export function findCompanyVoList(data) {
    return request.getRequest('findCompanyVoList')
}

export function findBusinessVoList(data) {
    return request.getRequest('findBusinessVoList?' + data)
}

export function updateBusinessVo(data) {
    return request.put('updateBusinessVo', data)
}
export function delBusiness(data) {
    return request.deleteRequest('delBusiness?bid=' + data)
}
export function delBusinessByIds(data) {
    return request.deleteRequest('delBusinessByIds?ids=' + data)
}
export function findCompanyList(data) {
    return request.getRequest('findCompanyList?' + data)
}

export function addCompany(data) {
    return request.post('addCompany', data)
}

export function delCompany(data) {
    return request.deleteRequest('delCompany?cid=' + data)
}

export function updateCompanyVo(data) {
    return request.put('updateCompanyVo', data)
}

export function findOrganizationList(data) {
    return request.getRequest('findOrganizationList?' + data)
}

export function addOrganization(data) {
    return request.post('addOrganization', data)
}

export function delOrganizationVo(data) {
    return request.deleteRequest('delOrganizationVo?orId=' + data)
}

export function updateOrganizationVo(data) {
    return request.put('updateOrganizationVo', data)
}


export function findSourceList(data) {
    return request.getRequest('findSourceList?' + data)
}

export function addSource(data) {
    return request.post('addSource', data)
}

export function delSource(data) {
    return request.deleteRequest('delSource?sid=' + data)
}

export function updateSource(data) {
    return request.put('updateSource', data)
}

export function downloadExcel(data) {
    return request.getRequest('downloadExcel?' + data)
}

export function updateModifyTime(data) {
    return request.put('updateModifyTime', data)
}

export function updateCreateTime(data) {
    return request.put('updateCreateTime', data)
}
export function createReport(data) {
    return request.getRequest('createReport?' + data)
}
export function findReportList(data) {
    return request.getRequest('findReportList?' + data)
}
export function updateReport(data) {
    return request.put('updateReport', data)
}
export function findAllUserNameList(data) {
    return request.getRequest('findUserNameList?' + data)
}
export function findCarTypeList(data) {
    return request.getRequest('findAllCarTypeList?' + data)
}

export function deleteCarTypeByCtId(data) {
    return request.deleteRequest('deleteCarType?ctId=' + data)
}

export function addCarType(data) {
    return request.post('addCarType', data)
}

export function updateCarType(data) {
    return request.put('updateCarType', data)
}