import Vue from 'vue'
import Vuex from 'vuex'
import { findMenuIten } from '@/api'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isShowLoading: false, // 全局 loading
        // 左侧菜单栏数据
        menuItems: [
            {
                name: 'home', // 要跳转的路由名称 不是路径
                size: 18, // icon大小
                type: 'md-home', // icon类型
                text: '主页', // 文本内容
            },
            // {
            //     text: '价格管理',
            //     type: 'ios-paper',
            //     children:[
            //         {
            //             name: "pricePage", // 要跳转的路由名称 不是路径
            //             size: 18, // icon大小
            //             type: 'ios-calculator', // icon类型
            //             text: '价格计算', // 文本内容
            //         },
            //         {
            //             name: 'CzPriceInfo', // 要跳转的路由名称 不是路径
            //             size: 18, // icon大小
            //             type: 'ios-calculator', // icon类型
            //             text: '材质价目表', // 文本内容
            //         },
            //         {
            //             name: 'processPriceInfo', // 要跳转的路由名称 不是路径
            //             size: 18, // icon大小
            //             type: 'ios-calculator', // icon类型
            //             text: '加工费表', // 文本内容
            //         },

            //     ]
            // },
            // {
            //     text: '系统设置',
            //     type: 'ios-cog',
            //     children: [
            //         {
            //             type: 'ios-grid',
            //             name: 't1',
            //             text: '表格',
            //             // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
            //             hidden: true,
            //         },
            //         {
            //             type: 'ios-people',
            //             name: 'userpage',
            //             text: '用户管理',
            //             // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
            //             // hidden: true,
            //         },
            //         {
            //             type: 'ios-people',
            //             name: 'Role',
            //             text: '角色管理',
            //             // hidden 属性 隐藏此菜单 可以通过在地址栏上输入对应的 URL 来显示页面
            //             // hidden: true,
            //         }
                // ],
            // },
        ],
    },
    mutations: {
        setMenus(state, items) {
            state.menuItems = [...items]
        },
        setLoading(state, isShowLoading) {
            state.isShowLoading = isShowLoading
        },
    },
    actions: {
        setMenus(content) {
            let uid = localStorage.getItem('uid')
            // console.log(uid);
            let data = 'uid=' + uid
            findMenuIten(data).then((res) => {
            // console.log(res)
            content.commit('setMenus', res.data)
            })
        },
    },

})

export default store