import Vue from 'vue'
import axios from 'axios'
import ViewUI from 'view-design'
import App from './App'
import store from './store'
import router from './router'
import 'view-design/dist/styles/iview.css'
import './permission'


Vue.config.productionTip = false
Vue.use(ViewUI)

Vue.prototype.$axios = axios
Vue.filter('toFiexd2', (value) => {
    if (!value) return '0.00'
    if (value === '') return '0.00'
    return Number(value).toFixed(2)
  })
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})
if (!Array.prototype.find) {
    Array.prototype.find = function (callback) {
        return callback && (this.filter(callback) || [])[0]
    }
}