import { LoadingBar } from 'view-design'
import router from './router'
import store from './store'
import createRoutes from '@/utils/createRoutes'
import { getDocumentTitle, resetTokenAndClearUser } from './utils'
import { findMenuIten } from '@/api'

// 是否有菜单数据
let hasMenus = false
router.beforeEach(async (to, from, next) => {
    document.title = getDocumentTitle(to.meta.title)
    LoadingBar.start()
    if (localStorage.getItem('token')) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else if (hasMenus) {
            next()
        } else {
            try {
                // 这里可以用 await 配合请求后台数据来生成路由
                // const data = await axios.get('findUserPermission?uid='+uid)
                // const routes = createRoutes(data)
                // console.log(store.state.menuItems)
                let uid = localStorage.getItem('uid')
                let param = 'uid=' + uid
                 findMenuIten(param).then((res) => {
                    const routes = createRoutes(res.data)
                    // 动态添加路由
                    router.addRoutes(routes)
                    hasMenus = true
                    next({ path: to.path || '/' })
                 })
                // console.log(data)
                // const routes = createRoutes(store.state.menuItems)
                // router.addRoutes(routes)
                // hasMenus = true
                // next({ path: to.path || '/' })
                // console.log(routes)


                // const routes = createRoutes(store.state.menuItems)
                // console.log(routes)
                // // 动态添加路由
                // router.addRoutes(routes)
                // hasMenus = true
                // next({ path: to.path || '/' })
            } catch (error) {
                console.log(error)
                resetTokenAndClearUser()
                next(`/login?redirect=${to.path}`)
            }
        }
    } else {
        hasMenus = false
        if (to.path === '/login') {
            next()
        } else {
            next(`/login?redirect=${to.path}`)
        }
    }
})

router.afterEach(() => {
    LoadingBar.finish()
})